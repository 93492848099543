<script setup lang="ts">
import { useForm } from 'vee-validate'
import { inject } from 'vue'
import { z } from 'zod'

import { Sm400Gray, Sm600Orange } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons/'
import VCheckbox from '~/components/ui/Inputs/VCheckbox.vue'
import VTextInput from '~/components/ui/Inputs/VText.vue'

import { useFieldValidation } from '~/composables/useFieldValidation'

import { hasSlotContent } from '~/utils/hasSlot'

import { noFloatInjectKey } from '~/inject-keys/NoFloat'

const noFloat = ref(inject(noFloatInjectKey) || false)

type Props = {
  email: string
  firstName: string
  isSocial?: boolean
  disableEmailInput?: boolean
  disabled?: boolean
}
type Emits = {
  'update-email': [email: string]
  'update-first-name': [firstName: string]
  'request-signup': []
  'request-validation': []
}
type Slots = {
  top(): any
  mid(): any
}

const slots = defineSlots<Slots>()
const emit = defineEmits<Emits>()
const props = withDefaults(defineProps<Props>(), {
  isSocial: false,
  disableEmailInput: false,
  disabled: false,
})

const { t } = useI18n()

const { validate: validateForm } = useForm<{
  email: string
  firstName: string
  cgu: boolean
}>()

const { value: emailFieldValue, errorMessage: emailFieldErrorMessage } =
  useFieldValidation(
    'email',
    z
      .string()
      .transform((value) => value.trim())
      .pipe(z.string().min(1, t('error.classic')))
      .pipe(z.string().email(t('auth.login.error.emailInvalid'))),
    {
      syncVModel: true,
      initialValue: props.email,
    },
  )
const { value: firstNameFieldValue, errorMessage: firstNameFieldErrorMessage } =
  useFieldValidation('firstName', z.string().min(1, t('error.classic')), {
    syncVModel: true,
    initialValue: props.firstName,
  })
// CGH = terms of services in french
const { value: cguFieldValue, errorMessage: cguFieldErrorMessage } =
  useFieldValidation(
    'cgu',
    z.boolean().refine((value) => value === true),
    { syncVModel: true, validateOnValueUpdate: false, initialValue: false },
  )

onMounted(() => {
  if (props.isSocial) return

  emit('update-email', '')
  emit('update-first-name', '')
})

const hasMidSlot = computed<boolean>(() => hasSlotContent(slots.mid))

async function handleMainBtnClick() {
  const { valid } = await validateForm()
  if (valid) emit('request-signup')
  else emit('request-validation')
}
</script>

<template>
  <div>
    <slot name="top"></slot>
    <div class="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-6">
      <VTextInput
        v-model="firstNameFieldValue"
        :p-validity="!firstNameFieldErrorMessage"
        :offset="80"
        :placeholder="
          $t('band.signup.init.createUserTemplate.placeholder.firstName')
        "
        data-test-id="artistSignupNameInput"
        :label="$t('band.signup.init.createUserTemplate.label.firstName')"
        @update:model-value="emit('update-first-name', $event)"
      >
        <template #err-message>
          <div class="errmsg">{{ firstNameFieldErrorMessage }}</div>
        </template>
      </VTextInput>
      <VTextInput
        v-model="emailFieldValue"
        :p-validity="!emailFieldErrorMessage"
        :offset="80"
        :disabled="disableEmailInput"
        :display-right-check="!disableEmailInput"
        data-test-id="artistSignupEmailInput"
        :label="$t('band.signup.init.createUserTemplate.label.email')"
        :placeholder="
          $t('band.signup.init.createUserTemplate.placeholder.email')
        "
        picto-left="fas fa-envelope"
        @update:model-value="emit('update-email', $event)"
      >
        <template #err-message>
          {{ emailFieldValue.length < 1 ? emailFieldErrorMessage : '' }}
        </template>
      </VTextInput>
    </div>
    <div v-if="hasMidSlot" class="tw-mb-6">
      <slot name="mid"></slot>
    </div>
    <div class="tw-mb-6 tw-flex tw-items-start tw-justify-start">
      <VCheckbox
        v-model="cguFieldValue"
        :p-validity="!cguFieldErrorMessage"
        data-test-id="artistSignupCguCheckbox"
      />
      <div class="tw-ml-2 tw-mt-px" :class="{ error: !cguFieldErrorMessage }">
        <Sm400Gray>{{
          $t('band.signup.init.createUserTemplate.input.cguPlain')
        }}</Sm400Gray>
        <a href="/lp/cgs/" target="_blank">
          <Sm600Orange class="tw-underline">{{
            $t('band.signup.init.createUserTemplate.input.cguUnderlined')
          }}</Sm600Orange>
        </a>
        <Sm400Gray>{{
          $t('band.signup.init.createUserTemplate.input.cgsAnd')
        }}</Sm400Gray>
        <NuxtLinkLocale to="/lp/cgs/" target="_blank" no-prefetch>
          <Sm600Orange class="tw-underline">{{
            $t('band.signup.init.createUserTemplate.input.cgsUnderlined')
          }}</Sm600Orange>
        </NuxtLinkLocale>
      </div>
    </div>
    <div class="btnWrapper" :class="{ noFloat }">
      <BtnOrange
        :disabled="disabled"
        data-test-id="artistSignupCreateAccountCTA"
        @click="handleMainBtnClick"
      >
        <div class="btnText">
          {{
            $t('band.signup.init.createUserTemplate.button.validationButton')
          }}
        </div>
      </BtnOrange>
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn {
  @apply tw-w-full;
}

.btnWrapper {
  @apply tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-rounded-none tw-bg-white tw-px-6 tw-py-4 tw-shadow-stickyBottom;

  @screen sm {
    @apply tw-static tw-block tw-p-0;
  }

  &.noFloat {
    @apply tw-static tw-mb-6 tw-block tw-bg-transparent tw-p-0 tw-shadow-none;

    @screen sm {
      @apply tw-mb-0;
    }
  }
}
</style>
